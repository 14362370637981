import React, { Fragment } from 'react';
import Helmet from 'react-helmet';

export const track = (events = {}) => {
	if (typeof window === 'undefined') return;

	for (let event in events) {
		const dataLayer = window.dataLayer || [];
		dataLayer.push(events[event]);
	}
};

// log the pageview with their URL
export const pageView = url => {
	window.gtag &&
		window.gtag('config', 'G-LK298HGTQP', {
			page_path: url,
		});

	window.fbq && window.fbq('track', 'PageView');
};

const Analytics = () => (
	<Fragment>
		<Helmet>
			{/* Google Analytics */}
			<script
				async
				src="https://www.googletagmanager.com/gtag/js?id=G-QVSNF5M0TJ"
			/>

			<script>
				{`
					window.dataLayer = window.dataLayer || [];
					function gtag(){
						dataLayer.push(arguments);
					}
					gtag('js', new Date());
					gtag('config', 'G-QVSNF5M0TJ');
					`}
			</script>
		</Helmet>
	</Fragment>
);

export default Analytics;
