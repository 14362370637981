// Polyfills
require('unfetch');

const React = require('react');
const Layout = require('./src/layout/Layout.jsx').default;
const { wrapRootElement } = require('./wrapRootElement');

// Wrap every page with the main layout
exports.wrapPageElement = ({ element, props }) => (
  <Layout {...props}>{element}</Layout>
);

exports.wrapRootElement = wrapRootElement;

// Credits

console.log(`
Site Credit
===========
Design: https://sylvia-perez.com/
Development : https://buena-suerte.studio
`);
