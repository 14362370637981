import React, { useContext, useEffect } from 'react';
import Context from '../context/Context';

import { SwitchTransition, Transition } from 'react-transition-group';

import cx from 'classnames';
import sanityImage from '../util/sanityImage';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';

export const TRANSITION_DURATION = 400;

const TRANSITION_STYLES = {
	default: {
		transition: `opacity ${TRANSITION_DURATION}ms ease`,
	},
	entering: {
		opacity: 0,
	},
	entered: {
		opacity: 1,
	},
	exiting: {
		opacity: 0,
	},
	exited: {
		opacity: 0,
	},
};

const LightBox = () => {
	const {
		isLightBoxOpen,
		featuredImage,
		setLightBoxOpen,
		setFeaturedImage,
	} = useContext(Context);

	const handleCloseButton = event => {
		event.preventDefault();
		setLightBoxOpen(false);

		setTimeout(() => {
			setFeaturedImage(null);
		}, 500);
	};

	useEffect(() => {
		const listener = event => {
			if (event.keyCode == 27 || event.key === 'Escape') {
				setLightBoxOpen(false);

				setTimeout(() => {
					setFeaturedImage(null);
				}, 500);
			}
		};
		if (isLightBoxOpen) {
			window.addEventListener('keydown', listener);
		}
		return () => {
			window.removeEventListener('keydown', listener);
		};
	}, [isLightBoxOpen]);

	return (
		<SwitchTransition>
			<Transition
				key={isLightBoxOpen}
				appear
				timeout={TRANSITION_DURATION}
			>
				{status => (
					<React.Fragment>
						{isLightBoxOpen && (
							<div
								className="modal__underlay  pf fill df fdc jcc aic p2 p5--lg of--scroll"
								style={{
									...TRANSITION_STYLES.default,
									...TRANSITION_STYLES[status],
								}}
								onClick={event => {
									const { target } = event;
									if (
										target.className.includes(
											'modal__underlay'
										)
									) {
										handleCloseButton(event);
									}
								}}
							>
								<button
									onClick={handleCloseButton}
									className="modal__button pf top right mt1 mr1 "
								>
									<svg
										width="46"
										height="45"
										viewBox="0 0 46 45"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M32.9982 11.3138L22.6273 21.6846L12.2565 11.3138C11.9962 11.0534 11.574 11.0534 11.3137 11.3138C11.0533 11.5741 11.0533 11.9962 11.3137 12.2566L21.6845 22.6275L11.3137 32.9983C11.0533 33.2586 11.0533 33.6807 11.3137 33.9411C11.574 34.2014 11.9962 34.2014 12.2565 33.9411L22.6274 23.5702L32.9982 33.9411C33.2586 34.2014 33.6807 34.2014 33.9411 33.9411C34.2014 33.6807 34.2014 33.2586 33.9411 32.9982L23.5702 22.6274L33.9411 12.2565C34.2014 11.9962 34.2014 11.574 33.9411 11.3137C33.6806 11.0534 33.2585 11.0534 32.9982 11.3138Z"
											fill="currentColor"
										/>
									</svg>
								</button>

								<div
									className={cx(
										'modal__content modal__content--lightbox',
										{
											portrait: featuredImage.portrait,
										}
									)}
								>
									{featuredImage && (
										<React.Fragment>
											<picture>
												<source
													srcSet={sanityImage(
														featuredImage.artwork
															.url,
														{
															w: 2000,
														}
													)}
													media="(min-width: 1600px)"
												/>
												<source
													srcSet={sanityImage(
														featuredImage.artwork
															.url,
														{
															w: 1600,
														}
													)}
													media="(min-width: 1200px)"
												/>
												<source
													srcSet={sanityImage(
														featuredImage.artwork
															.url,
														{
															w: 1200,
														}
													)}
													media="(min-width: 800px)"
												/>
												<source
													srcSet={sanityImage(
														featuredImage.artwork
															.url,
														{
															w: 1000,
														}
													)}
													media="(min-width: 600px)"
												/>
												<img
													alt={
														featuredImage.title ||
														'Library Street Collective'
													}
													className="db x b--black"
													src={sanityImage(
														featuredImage.artwork
															.url,
														{
															w: 800,
														}
													)}
												/>
											</picture>
											{featuredImage.title && (
												<p className="mt2 sans--14 sans--18--lg tc ">
													{featuredImage.title}
												</p>
											)}
											{featuredImage.description && (
												<div className="mt2 sans--14 sans--18--lg mxa tc rich-text line-break df fdc aic ">
													<BlockContent
														blocks={
															featuredImage.description
														}
														serializers={richText}
													/>
												</div>
											)}
										</React.Fragment>
									)}
								</div>
							</div>
						)}
					</React.Fragment>
				)}
			</Transition>
		</SwitchTransition>
	);
};

export default LightBox;
