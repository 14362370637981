import React from 'react';
import { Link } from 'gatsby';

export default ({
  _type,
  title,
  url,
  product,
  slug,
  openInNewWindow,
  productName,
  collectionName,
  children,
  linkType,
  ...props
}) => {
  if (url[0] === '/') {
		return (
			<Link
				{...props}
				title={title}
				target={openInNewWindow ? '_blank' : null}
				rel={openInNewWindow ? 'noopener noreferrer' : null}
				to={url}
			>
				{children || title}
			</Link>
		);
  } else {
		return (
			<a
				{...props}
				title={title}
				target={openInNewWindow ? '_blank' : null}
				rel={openInNewWindow ? 'noopener noreferrer' : null}
				href={url}
			>
				{children || title}
			</a>
		);
  }
};
