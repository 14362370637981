import React from 'react';
import classNames from 'classnames';
import { defaultSerializers } from '@sanity/block-content-to-react';
import { Link } from 'gatsby';

export default {
  types: {
    block: props => {
      const { style = 'normal' } = props.node;

      if (style === 'h1') {
        return <h1>{props.children}</h1>;
      } else if (style === 'h2') {
        return <h2>{props.children}</h2>;
      } else if (style === 'h3') {
        return <h3>{props.children}</h3>;
      }

      // Fall back to default handling
      return defaultSerializers.types.block(props);
    },
  },
  marks: {
    link: props => {
		if (props?.mark?.href && props?.mark?.href[0] === '/') {
			return (
				<Link
					title={props.mark.title}
					target={props.mark.openInNewWindow ? '_blank' : null}
					to={props.mark.href}
				>
					{props.children}
				</Link>
			);
		}
		return (
			<a title={props.mark.title} target="_blank" href={props.mark.href}>
				{props.children}
			</a>
		);
	},
  },
};
