// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-404-jsx": () => import("./../../../src/templates/404.jsx" /* webpackChunkName: "component---src-templates-404-jsx" */),
  "component---src-templates-about-page-jsx": () => import("./../../../src/templates/AboutPage.jsx" /* webpackChunkName: "component---src-templates-about-page-jsx" */),
  "component---src-templates-alliance-index-jsx": () => import("./../../../src/templates/AllianceIndex.jsx" /* webpackChunkName: "component---src-templates-alliance-index-jsx" */),
  "component---src-templates-alliance-jsx": () => import("./../../../src/templates/Alliance.jsx" /* webpackChunkName: "component---src-templates-alliance-jsx" */),
  "component---src-templates-anatomy-jsx": () => import("./../../../src/templates/Anatomy.jsx" /* webpackChunkName: "component---src-templates-anatomy-jsx" */),
  "component---src-templates-artist-jsx": () => import("./../../../src/templates/Artist.jsx" /* webpackChunkName: "component---src-templates-artist-jsx" */),
  "component---src-templates-artist-list-jsx": () => import("./../../../src/templates/ArtistList.jsx" /* webpackChunkName: "component---src-templates-artist-list-jsx" */),
  "component---src-templates-exhibition-jsx": () => import("./../../../src/templates/Exhibition.jsx" /* webpackChunkName: "component---src-templates-exhibition-jsx" */),
  "component---src-templates-exhibitions-index-jsx": () => import("./../../../src/templates/ExhibitionsIndex.jsx" /* webpackChunkName: "component---src-templates-exhibitions-index-jsx" */),
  "component---src-templates-home-jsx": () => import("./../../../src/templates/Home.jsx" /* webpackChunkName: "component---src-templates-home-jsx" */),
  "component---src-templates-news-page-jsx": () => import("./../../../src/templates/NewsPage.jsx" /* webpackChunkName: "component---src-templates-news-page-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/Page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-preview-jsx": () => import("./../../../src/templates/Preview.jsx" /* webpackChunkName: "component---src-templates-preview-jsx" */),
  "component---src-templates-product-jsx": () => import("./../../../src/templates/Product.jsx" /* webpackChunkName: "component---src-templates-product-jsx" */),
  "component---src-templates-project-jsx": () => import("./../../../src/templates/Project.jsx" /* webpackChunkName: "component---src-templates-project-jsx" */),
  "component---src-templates-project-list-jsx": () => import("./../../../src/templates/ProjectList.jsx" /* webpackChunkName: "component---src-templates-project-list-jsx" */),
  "component---src-templates-queue-jsx": () => import("./../../../src/templates/Queue.jsx" /* webpackChunkName: "component---src-templates-queue-jsx" */),
  "component---src-templates-search-jsx": () => import("./../../../src/templates/Search.jsx" /* webpackChunkName: "component---src-templates-search-jsx" */),
  "component---src-templates-shop-jsx": () => import("./../../../src/templates/Shop.jsx" /* webpackChunkName: "component---src-templates-shop-jsx" */),
  "component---src-templates-site-jsx": () => import("./../../../src/templates/Site.jsx" /* webpackChunkName: "component---src-templates-site-jsx" */)
}

