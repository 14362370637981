import React, { useState } from 'react';
import Link from './Link';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';
import { email as emailRegex } from 'magic-tricks/lib/regex';
import xss from 'xss';
import phoneNumberFormatter from 'phone-number-formats';

export default ({
	address,
	addressTwo,
	phone,
	email,
	links = [],
	newsletterCTA,
	newsletterID,
	newsletterCopy,
}) => {
	const [state, setState] = useState({
		consent: false,
		email: null,
		isSuccess: false,
		isError: false,
		isSubmitting: false,
		showInvalidEmailError: false,
	});

	const onChangeEmail = event => {
		const { showInvalidEmailError } = state;
		setState({
			...state,
			email: xss(event.target.value),
			showInvalidEmailError: showInvalidEmailError
				? !emailRegex.test(event.target.value)
				: showInvalidEmailError,
		});
	};

	const handleSubmit = async event => {
		event.preventDefault();

		if (!state.consent) {
			setState({
				...state,
				showInvalidEmailError: false,
				isSubmitting: false,
				isError: 'Please accept the terms & conditions below',
			});
			return;
		}

		if (!state.email) {
			setState({
				...state,
				showInvalidEmailError: false,
				isSubmitting: false,
				isError: 'Please enter your email.',
			});
			return;
		}

		if (!emailRegex.test(state.email)) {
			setState({
				...state,
				showInvalidEmailError: true,
				isError: false,
				isSubmitting: false,
			});

			return;
		}

		setState({
			...state,
			isSubmitting: true,
			isError: false,
			showInvalidEmailError: false,
		});

		fetch('/.netlify/functions/email', {
			method: 'POST',
			body: JSON.stringify({
				email: state.email,
			}),
		})
			.then(res => {
				return res.json();
			})
			.then(response => {
				if (response.success) {
					setState({
						...state,
						isError: null,
						isSuccess: 'Thank you for signing up!',
					});
				}
			})
			.catch(error => {
				console.error(error);
				setState({
					...state,
					isError:
						'Uh oh. Something went wrong. Please try again later.',
				});
			});
	};

	const phoneNumber =
		phone &&
		new phoneNumberFormatter(phone.toString()).format({
			type: 'domestic',
		});

	return (
		<footer className="footer bt--black">
			<div className="grid-container contained">
				<section className="row df fw">
					<aside className="col c6--md c3--lg mt2 mt5--md">
						<div className="sans--14 sans--18--md">
							<p>Library Street Collective</p>
							<BlockContent
								blocks={address}
								serializers={richText}
							/>
						</div>
					</aside>
					<aside className="col c6--md c3--lg mt2 mt5--md">
						<div className="sans--14 sans--18--md">
							<BlockContent
								blocks={addressTwo}
								serializers={richText}
							/>
						</div>
					</aside>
					<aside className="col c6--md c2--lg mt2 mt5--md">
						<div className="sans--14 sans--18--md">
							{phone && (
								<Link
									url={`tel:${phone}`}
									className="link--underline-reverse fit db"
								>
									+1 {phoneNumber.string || phone}
								</Link>
							)}
							{email && (
								<Link
									url={`mailto:${email}`}
									className="link--underline-reverse fit db"
								>
									{email}
								</Link>
							)}
							{links.map(link => {
								return (
									<Link
										key={link._key}
										url={link.url}
										{...link}
										className="link--underline-reverse fit db"
									>
										{link.title}
									</Link>
								);
							})}
						</div>
					</aside>
					<section className="col c4--lg mt2 mt5--md tile--large">
						<p className="sans--24">
							{newsletterCTA || 'Subscribe to our Newsletter'}
						</p>
						<form name="contact" className="mt2">
							<div className="pr df aic">
								<label className="input__label  x">
									<input
										type="email"
										name="email"
										placeholder="Your Email Address*"
										className="input__input x y p1 sans--18"
										required
										onChange={onChangeEmail}
									/>
								</label>

								<button
									className="newsletter__button pa"
									disabled={!state.consent}
									onClick={handleSubmit}
								>
									→
								</button>
							</div>
							{state.isError && (
								<p className="error sans--14">
									{state.isError}
								</p>
							)}
							{state.showInvalidEmailError && (
								<p className="error sans--14">
									Please fix your email address.
								</p>
							)}
							{state.isSuccess && (
								<p className="sans--14">{state.isSuccess}</p>
							)}
							<div className="mt2 df">
								<label className="newsletter__email-consent mr1">
									<input
										type="checkbox"
										name="email-consent"
										onChange={e =>
											setState({
												...state,
												consent:
													e.currentTarget.checked,
											})
										}
									/>
								</label>
								{/* <span className="sans--14 col c8">
									By sharing your details you agree to our
									Privacy Policy and Terms and Conditions. And
									you will receive gallery newsletters.
								</span> */}
								{newsletterCopy && (
									<div className="rich-text sans--14 col c8">
										<BlockContent
											blocks={newsletterCopy}
											serializers={richText}
										/>
									</div>
								)}
							</div>
						</form>
					</section>
				</section>

				<section className="mb1 mt5 df jcb">
					<small className="sans--12">
						© {new Date().getFullYear()} Library Street Collective
					</small>
				</section>
			</div>
		</footer>
	);
};
