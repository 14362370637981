import React, { useContext } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import Context from '../context/Context';

const Cart = ({ enableBanner }) => {
	const {
		checkout,
		updateQuantityInCart,
		isCartOpen,
		setCartOpen,
	} = useContext(Context);

	const { lineItems = [] } = checkout;

	const goToCheckout = () => {
		const checkoutURL = checkout.webUrl;
		window.open(checkoutURL, '_self');
	};

	return (
		<React.Fragment>
			<aside
				className={classNames('cart__modal pf df fdc', {
					open: isCartOpen,
					'banner-open': enableBanner,
				})}
			>
				<header className="site-header df aic x jcb bb--black pl2 pr2">
					<p className="sans--14 sans--18--md">Cart</p>
					<button
						className="cart__close sans--14 sans--18--md"
						onClick={() => setCartOpen(false)}
					>
						Close
					</button>
				</header>
				{lineItems.length > 0 ? (
					<React.Fragment>
						<section className="cart__content df fdc ofy--scroll pl2 pr2">
							{lineItems.map(item => {
								return (
									<article
										className="cart__product df mt2 pt2 bt--black-tint pt3--lg mt3--lg"
										key={item.id}
									>
										<section className="cart__image">
											<div className="img--square">
												{item?.variant?.image && (
													<img
														alt={item.title}
														className="img--multiply x"
														src={`${item.variant.image.src}?w=600&auto=format`}
													/>
												)}
											</div>
										</section>
										<section className="cart__product-desc df fdc pl2 pl3--lg">
											{item.vendor && (
												<p className="mono--13 ">
													{item.vendor}
												</p>
											)}
											<p className="serif--22">
												{item.title}
											</p>

											<div className="df jcb cart__product-controls pt1 pt0--lg">
												<div className="cart__price pb1 pb0--lg">
													<p className="  ">Price</p>
													<p className="serif--22">
														$
														{
															item.variant.price
																.amount
														}
													</p>
												</div>

												<div className="cart__quantity-buttons quantity-selector quantity-selector--cart df jcb aic x">
													<button
														className=""
														onClick={() =>
															updateQuantityInCart(
																item.id,
																item.quantity -
																	1
															)
														}
													>
														–
													</button>
													<p className="">
														{item.quantity}
													</p>
													<button
														className=""
														onClick={() =>
															updateQuantityInCart(
																item.id,
																item.quantity +
																	1
															)
														}
													>
														+
													</button>
												</div>
											</div>
										</section>
									</article>
								);
							})}
						</section>
						<footer className="cart__footer bt--black-tint p2">
							<div className="cart__subtotal df jcb x mb2">
								<p className="sans--14 sans--18--md">
									Subtotal
									<small className="db mt1 sans--12 sans--14--md">
										Your shipping costs will be calculated
										at checkout
									</small>
								</p>
								<p className="serif--22">
									${checkout.subtotalPrice.amount}
								</p>
							</div>
							<button
								className="button--checkout tile--large db x tc"
								onClick={goToCheckout}
							>
								Proceed to checkout
							</button>
						</footer>
					</React.Fragment>
				) : (
					<section className="cart__empty mt5 p2">
						<p className="sans--14 sans--18--md mb2">
							Your cart is currently empty.
						</p>

						<Link
							className="tile--large db tc"
							to="/"
							onClick={() => setCartOpen(!isCartOpen)}
						>
							Continue Shopping.
						</Link>
					</section>
				)}
			</aside>

			<div
				className={classNames(
					'cart__underlay pf fill top bottom left right',
					{
						open: isCartOpen,
					}
				)}
				onClick={() => setCartOpen(false)}
			/>
		</React.Fragment>
	);
};

export default Cart;
