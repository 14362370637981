import React, { useState, useContext } from 'react';
import Context from '../context/Context';
import { Link } from 'gatsby';
import { BurgerIcon } from './SVG';
import { SearchIcon } from './SVG';

import cx from 'classnames'
import reduce from 'lodash/reduce'

const Header = ({ desktopMenu = [], mobileMenu = [] }) => {
	const {
		checkout,
		setCartOpen,
		setSearchOpen,
		isMenuOpen,
		setMenuOpen,
	} = useContext(Context);

	const { lineItems = [] } = checkout;

	const cartCount = reduce(
		lineItems,
		(total, item) => {
			return total + item.quantity;
		},
		0
	);

	return (
		<React.Fragment>
			<header className="site-header df jcb aic psy fw bb--black">
				<section className="site-header__main-nav x df jce aic grid-container contained">
					<Link
						onClick={() => {
							setTimeout(() => {
								setMenuOpen(false);
							}, 100);
						}}
						to="/"
						className="site-header__logo"
					>
						Library Street Collective
					</Link>
					<nav className="site-header__desktop-nav df aic force-hide">
						{desktopMenu && desktopMenu.length > 0 && (
							<React.Fragment>
								{desktopMenu.map((item, index) => (
									<Link
										className={cx(
											'sans--14 sans--18--md ',
											{
												mr2:
													index <
													desktopMenu.length - 1,
											}
										)}
										to={item.url}
										key={item._key}
									>
										{item.title}
									</Link>
								))}
							</React.Fragment>
						)}
						<button
							className="sans--14 sans--18--md ml2"
							onClick={() => setSearchOpen(true)}
						>
							Search
						</button>
						{cartCount > 0 && (
							<button
								className="sans--14 sans--18--md ml2"
								onClick={() => setCartOpen(true)}
							>
								Cart ({cartCount})
							</button>
						)}
					</nav>
					<button
						className="search-icon-ph"
						onClick={() => setSearchOpen(true)}
					>
						<SearchIcon
							className={cx('icon icon--horizontal', {
								open: isMenuOpen,
							})}
						/>
					</button>
					<button
						className="menu-icon"
						onClick={() => setMenuOpen(!isMenuOpen)}
					>
						<BurgerIcon
							className={cx('icon icon--horizontal', {
								open: isMenuOpen,
							})}
						/>
					</button>
				</section>
			</header>

			<aside
				className={cx(
					'mobile-menu pf fill z10 bg--white grid-container pt2',
					{
						active: isMenuOpen,
					}
				)}
			>
				{mobileMenu && mobileMenu.length > 0 && (
					<React.Fragment>
						{mobileMenu.map((item, index) => (
							<Link
								onClick={() => {
									setTimeout(() => {
										setMenuOpen(false);
									}, 100);
								}}
								className={cx('db sans--32', {
									mt1: index > 0,
								})}
								to={item.url}
							>
								{item.title}
							</Link>
						))}
					</React.Fragment>
				)}
				<button
					className="sans--32 db mt1 force-hide"
					onClick={() => setSearchOpen(true)}
				>
					Search
				</button>
				{cartCount > 0 && (
					<button
						className="sans--32 db cart-button-ph"
						onClick={() => setCartOpen(true)}
					>
						Cart ({cartCount})
					</button>
				)}
			</aside>
		</React.Fragment>
	);
};

export default Header;
