import React, { useEffect } from 'react';
import { SwitchTransition, Transition } from 'react-transition-group';
import Analytics, { pageView } from '../components/Analytics';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Cart from '../components/Cart';
import LightBox from '../components/LightBox';
import SearchForm from '../components/SearchForm';
import '../styles/main.scss';

export const TRANSITION_DURATION = 400;

const TRANSITION_STYLES = {
	default: {
		transition: `opacity ${TRANSITION_DURATION}ms ease`,
	},
	entering: {
		opacity: 0,
	},
	entered: {
		opacity: 1,
	},
	exiting: {
		opacity: 0,
	},
	exited: {
		opacity: 0,
	},
};

const Layout = ({ pageContext, children, location }) => {
	const { header, footer } = pageContext;

	useEffect(() => {
		pageView(location.pathname);
	}, [location]);

	return (
		<React.Fragment>
			<Analytics />
			<Header {...header} location={location} />
			<LightBox />
			<SearchForm />
			<SwitchTransition>
				<Transition
					key={location.pathname}
					mountOnEnter
					unmountOnExit
					appear
					timeout={TRANSITION_DURATION}
				>
					{status => (
						<main
							id="maincontent"
							style={{
								...TRANSITION_STYLES.default,
								...TRANSITION_STYLES[status],
							}}
						>
							<Cart />
							{children}
							<Footer {...footer} />
						</main>
					)}
				</Transition>
			</SwitchTransition>
		</React.Fragment>
	);
};

export default Layout;
