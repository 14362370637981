import React, { useState, useEffect } from 'react';
import shop from 'shopify-buy';
import { encode } from 'shopify-gid';
import cookie from 'js-cookie';
import algoliaClient from '../api/algolia';

const searchIndex = algoliaClient.initIndex(process.env.GATSBY_ALGOLIA_INDEX);

export const CHECKOUT_KEY = 'lsc_checkout';

const shopify = shop.buildClient({
	domain: process.env.GATSBY_SHOPIFY_DOMAIN,
	storefrontAccessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_API_TOKEN,
});

const ShopContext = React.createContext();
export default ShopContext;

export const ShopContextProvider = ({ children }) => {
	const [isMenuOpen, setMenuOpen] = useState(false);
	const [isCartOpen, setCartOpen] = useState(false);
	const [isSearchOpen, setSearchOpen] = useState(false);
	const [isLightBoxOpen, setLightBoxOpen] = useState(false);
	const [featuredImage, setFeaturedImage] = useState(false);
	const [searchResults, setSearchResults] = useState([]);

	const [isHydratingCheckout, setIsHydratingCheckout] = useState(false);
	const [checkout, setCheckout] = useState({ lineItems: [] });
	const [products, setProducts] = useState([]);
	const [shop, setShop] = useState({});
	const [error, setError] = useState(null);
	const [successMessage, setSuccessMessage] = useState(null);

	useEffect(() => {
		let productsPromise = shopify.product.fetchAll(250);
		let shopPromise = shopify.shop.fetchInfo();

		Promise.all([productsPromise, shopPromise]).then(response => {
			let products = response[0];
			let shop = response[1];

			setShop(shop);
			setProducts(products);
		});

		hydrateCheckout();
	}, []);

	const hydrateCheckout = () => {
		const checkoutId = cookie.get(CHECKOUT_KEY);

		setIsHydratingCheckout(true);

		if (checkoutId) {
			shopify.checkout
				.fetch(checkoutId)
				.then(checkout => {
					if (checkout.completedAt) {
						shopify.checkout.create().then(newCheckout => {
							setCheckout(newCheckout);
						});
					} else {
						setCheckout(checkout);
					}
				})
				.catch(error => console.warn(error));
		} else {
			shopify.checkout.create().then(checkout => {
				setCheckout(checkout);
			});
		}
	};

	const addVariantToCart = (variantId, quantity) => {
		const productVariant = encode('ProductVariant', variantId, {
			accessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_API_TOKEN,
		});

		const lineItemsToAdd = [
			{ variantId: productVariant, quantity: quantity },
		];
		const checkoutID = checkout.id;

		shopify.checkout
			.addLineItems(checkoutID, lineItemsToAdd)
			.then(updatedCheckout => {
				cookie.set(CHECKOUT_KEY, checkout.id, {
					expires: 25,
				});
				setCheckout(updatedCheckout);
				setError(null);
				setCartOpen(true);
			})
			.catch(error => {
				setError(error);
				setSuccessMessage(null);
			});
	};

	const updateQuantityInCart = (lineItemID, quantity) => {
		const checkoutID = checkout.id;
		const lineItemsToUpdate = [
			{ id: lineItemID, quantity: Number(quantity) },
		];

		shopify.checkout
			.updateLineItems(checkoutID, lineItemsToUpdate)
			.then(updatedCheckout => {
				cookie.set(CHECKOUT_KEY, checkout.id, {
					expires: 25,
				});
				setCheckout(updatedCheckout);
			});
	};

	const removeLineItemInCart = lineItemID => {
		const checkoutID = checkout.id;

		shopify.checkout
			.removeLineItems(checkoutID, [lineItemID])
			.then(updatedCheckout => {
				setCheckout(updatedCheckout);
			});
	};

	const fetchProduct = productId => {
		const productVariant = encode('Product', productId, {
			accessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_API_TOKEN,
		});

		return shopify.product
			.fetch(productVariant)
			.then(product => {
				return product;
			})
			.catch(error => {
				console.warn(error);
			});
	};

	const searchSite = searchTerm => {
		searchIndex
			.search(searchTerm)
			.then(({ hits }) => {
				setSearchResults(hits);
			})
			.catch(error => {
				setError(error);
				setSearchResults([]);
			});
	};

	const value = {
		// values
		products,
		shop,
		checkout,
		isMenuOpen,
		isCartOpen,
		isSearchOpen,
		successMessage,
		searchResults,
		isLightBoxOpen,
		featuredImage,
		error,
		// methods
		hydrateCheckout,
		setMenuOpen,
		setCartOpen,
		setSearchOpen,
		addVariantToCart,
		updateQuantityInCart,
		removeLineItemInCart,
		fetchProduct,
		setLightBoxOpen,
		setFeaturedImage,
		searchSite,
	};

	return (
		<ShopContext.Provider value={value}>{children}</ShopContext.Provider>
	);
};
