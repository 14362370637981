import map from 'lodash/map';

const DEFAULT_PROPS = {
  auto: 'format',
  q: '100',
};

export default (src = '', props = {}) => {
  const updatedProps = {
    ...DEFAULT_PROPS,
    ...props,
  };

  const query = map(updatedProps, (value, key) => `${key}=${value}`);

  return `${src}?${query.join('&')}`;
};
