import React, { useState, useContext, useEffect, useRef } from 'react';
import Context from '../context/Context';
import { navigate } from 'gatsby';

import cx from 'classnames';

const SearchForm = () => {
	const { isSearchOpen, setSearchOpen, setMenuOpen } = useContext(Context);
	const [searchTerm, setSearchTerm] = useState('');
	const inputRef = useRef(null);

	const handleCloseButton = event => {
		event.preventDefault();
		setSearchOpen(false);
	};

	const handleChange = event => {
		const { target } = event;
		setSearchTerm(target.value);
	};

	const handleSubmit = event => {
		event.preventDefault();
		setMenuOpen(false);
		setSearchOpen(false);
		navigate(`/search?q=${searchTerm}`, {
			replace: true,
		});
	};

	useEffect(() => {
		const listener = event => {
			if (event.keyCode == 27 || event.key === 'Escape') {
				setSearchOpen(false);
			}
		};
		if (isSearchOpen) {
			window.addEventListener('keydown', listener);
		}
		return () => {
			window.removeEventListener('keydown', listener);
		};
	}, [isSearchOpen]);

	useEffect(() => {
		if (inputRef.current && isSearchOpen) {
			setTimeout(() => {
				inputRef.current.focus();
			}, 500);
		}
		if (!isSearchOpen) {
			setTimeout(() => {
				setSearchTerm('');
			}, 1000);
		}
		return () => {
			setSearchTerm('');
		};
	}, [isSearchOpen]);

	return (
		<div
			className={cx(
				'modal__underlay modal__underlay--search  pf fill df fdc jcc aic p2 p5--lg of--scroll',
				{
					active: isSearchOpen,
				}
			)}
			onClick={event => {
				const { target } = event;
				if (target.className.includes('modal__underlay')) {
					handleCloseButton(event);
				}
			}}
		>
			<button
				onClick={handleCloseButton}
				className="modal__button pf top right mt1 mr1 "
			>
				<svg
					width="46"
					height="45"
					viewBox="0 0 46 45"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M32.9982 11.3138L22.6273 21.6846L12.2565 11.3138C11.9962 11.0534 11.574 11.0534 11.3137 11.3138C11.0533 11.5741 11.0533 11.9962 11.3137 12.2566L21.6845 22.6275L11.3137 32.9983C11.0533 33.2586 11.0533 33.6807 11.3137 33.9411C11.574 34.2014 11.9962 34.2014 12.2565 33.9411L22.6274 23.5702L32.9982 33.9411C33.2586 34.2014 33.6807 34.2014 33.9411 33.9411C34.2014 33.6807 34.2014 33.2586 33.9411 32.9982L23.5702 22.6274L33.9411 12.2565C34.2014 11.9962 34.2014 11.574 33.9411 11.3137C33.6806 11.0534 33.2585 11.0534 32.9982 11.3138Z"
						fill="currentColor"
					/>
				</svg>
			</button>

			<form
				onSubmit={handleSubmit}
				className="modal__content modal__content--search df fdc jcc aic"
			>
				<label>
					<input
						ref={inputRef}
						type="text"
						name="search"
						className="modal__search-input sans--18 sans--36--md sans--36--lg tc "
						placeholder="Search Library Street"
						onChange={handleChange}
						value={searchTerm}
					/>
				</label>
				<div className="mt2 mt5--lg">
					<button
						className="button--tile button-ph sans--14 sans--14--md sans--14--lg "
						type="submit"
					>
						Search
					</button>
				</div>
			</form>
		</div>
	);
};

export default SearchForm;
